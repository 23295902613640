.modal-container
    margin-top: 4rem
    @media screen and ( min-height: 800px)
        margin-top: 10rem
.modal-dialog
    max-width: 60% !important
    margin: 0 auto
    @media screen and (min-height: 500px) and ( min-width: 1000px) and ( max-width: 1500px)
        max-width: 60% !important
    .modal-content
        .modal-header
            border: none
        .modal-body
            margin: 2rem 5%
            padding: 2rem
        .modal-footer
            border: none
            display: flex
            justify-content: center
@media screen and ( min-width: 1400px )
    .modal-dialog
        max-width: 65% !important
@media screen and ( min-width: 1700px )
    .modal-dialog
        max-width: 45% !important
@media screen and ( min-width: 1900px )
    .modal-dialog
        max-width: 48% !important