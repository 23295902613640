.containerRate
    position: relative
    .doubleButtonsText
        bottom: -80px
        font-weight: $regular
        position: absolute
        strong
            font-weight: $bold

.btn-customprimary
    background-color: $wompi-primary !important
    border-radius: 5rem !important
    color: #fff !important
    padding: .75rem 2rem !important
    font-weight: 600 !important
    &:hover
        background-color: $wompi-primary-hover !important

    
.btn-customsecondary
    width: 19ch !important
    background-color: $wompi-light !important
    border-radius: 5rem !important
    padding: .75rem 2rem !important
    font-weight: 500 !important
    &:hover
        background-color: $wompi-light-hover !important


.buttonsRateContainer
    display: flex
    flex-direction: row
    position: absolute
    .btn-small-rate
        font-size: 1.5rem !important
        padding: 0.2rem 1rem !important
        background-color:#C4C4C4
        color:#343534
        outline: 0
        border-color:#C4C4C4
        &:hover,&:active,&:focus
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important
            outline: 0
        &:hover
            transition: .3s
            background-color:#a7a6af
            color:#000000
            border-color:#686565
        &:focus,&:active
            background-color:#6E6B7B
            color:#C4C4C4
            border-color:#C4C4C4
    .btn-pressedDown
        background-color: #6E6B7B
        border-color: #6E6B7B
        color: #C4C4C4
    .btn-released
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset

#illustrations-container
    height: 5rem
    gap: 1rem
    display: flex
    flex-direction: row
    margin-left: 5rem
    @media screen and (min-height: 800px)
        height: 7rem
    @media screen and ( min-width: 1500px )
        margin-left: 5.5rem
#deliveryPromise,
#deliveryTimes
    position: relative
    .blackout
        position: absolute
        top: 0
        left: 0
        background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.81))
        z-index: 10
        width: 100%
        height: 100%

.bg-secommerce
    height: 100vh
    overflow: hidden
    background-color: $wompi-blue

.wompi
    background-color: $wompi-blue
    color: #FAFAFA



.deliveryTimeBox
    background-color: white
    padding: 4rem
    margin: 2rem
    border-radius: 1rem
    margin-top: 7rem
.input-placeholder-box
    position: relative



    

#locationList,
#locationSingle,
#distributionCenter,
#deliveryPromise,
#deliveryTimes,
#finish,
#panel-m
    .row
        .content-wrapper
            padding: 2rem
            width: 95%
            margin-left: 2.5%
            border-radius: 1rem
            background-color: $lightText
            position: relative
            .content-locations-list,
            .delivery-promise-list,
            .delivery-promise-time,
            .delivery-promise-rate
                height: 75vh
                overflow-y: auto
                overflow-x: hidden
                margin-bottom: 1.5rem
                .unit
                    display: flex
                    justify-content: space-around
                    align-items: center
                    border-radius: 7px
                    padding: 0 1rem 0 0
                    background-color: $backgroundLightblue
                    &.single
                        width: fit-content
            .delivery-promise-list
                height: 50vh
                margin-bottom: 0.5rem
            .delivery-promise-rate
                height: 25vh
                #rate-wrapper
                    display: grid
                    grid-template-columns: 0.2fr 0.15fr 1fr
                    align-items: center
                    margin-top: 2rem
                    height: 50px
                    div
                        &:nth-child(1)  
                            margin-bottom: 0!important
                        &:nth-child(2)  
                            display: flex
                            justify-content: center
                            align-items: center
                            margin-top: 0
                    small
                        margin-left: 1rem
            .delivery-type
                height: fit-content
                h3
                    margin-bottom: 2rem
                #subtype-wrapper
                    padding-left: 2rem
            .delivery-promise-time
                height: fit-content
                margin-bottom: 3rem
            .form-grid-two-columns
                padding: 0.5rem 0rem
                display: grid
                grid-template-columns: 1fr 1fr
                grid-gap: 1rem
                .double-input-col
                    display: grid
                    grid-template-columns: 1fr 1fr
                    grid-gap: 1rem
            .bottomPage
                position: absolute
                bottom: 1.5rem
                right: 2rem
            .map-container
                display: flex
                justify-content: center
                align-items: center
                height: 52vh
                img
                    width: 40%
            .doubleBtn
                width: 93%
                display: flex
                justify-content: space-between
                align-items: center
                margin-top: 1.5rem
                a
                    text-decoration: none
                    img
                        margin-right: 1rem
            .finish-wrapper
                height: 95%
                display: flex
                flex-direction: column
                justify-content: center
                padding: 0 5rem                     
                .next-steps
                    background-color: $backgroundLight
                    border-radius: 17px
                    margin: 2rem auto
                    padding: 2rem
                    text-align: center
                    width: 100%
            .finish-link
                text-decoration: none

@media screen and ( max-width: 1367px )
    #locationList,
    #locationSingle,
    #distributionCenter,
    #deliveryPromise,
    #deliveryTimes,
    #finish
        overflow: hidden
        .row
            .content-wrapper
                padding: 1.5rem 2rem 1rem
                .content-locations-list
                    height: 55vh
                .delivery-promise-list
                    height: 42vh
                .delivery-promise-rate
                    height: fit-content
                    margin-bottom: 0
                    #rate-wrapper
                        margin-top: 1rem
                        height: 40px
                .form-grid-two-columns
                    padding: 0.5rem 0
                .finish-wrapper
                    padding: 0 6rem
                    .next-steps
                        padding: 1rem
                .map-container
                    height: 40vh
                    img
                        width: 40%
    #finish
        .row
            .content-wrapper
                padding: 3rem

@media screen and ( max-width: 1025px )
    #locationList,
    #locationSingle,
    #distributionCenter,
    #deliveryPromise,
    #deliveryTimes,
    #finish
        .row
            .content-wrapper
                .content-locations-list
                    height: 60vh
                .delivery-promise-list
                    height: 47vh
                .delivery-promise-rate
                    height: 25vh
                .finish-wrapper
                    padding: 0 3rem
                    .next-steps
                        padding: 1rem 2rem
                .doubleBtn
                    width: 91%