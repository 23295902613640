*
    font-variant-ligatures: none
.bg-gray
    background-color: $grayMisc !important
    color: #2C2A29  !important
.loading-spinner
    position: fixed
    right: 22.5%
.opacity-0
    opacity: 0
    transition: .5s
.m-0
    margin: 0
.error-border
    border-color: #FF5B5B !important
.error-text
    color: #FF5B5B
    font-size: .9rem
    @media screen and ( min-height: 800px )
        margin-top: .25rem 

body
    font-family: $main-font
    
    color: $blackText
    letter-spacing: -0.43px
    .main-title,
    .finish-main-title
        font-size: 2rem
        line-height: 2.743rem
        margin-bottom: 1rem
        font-weight: $medium
        color: $blackText
    .finish-main-title
        font-weight: $bold
        color: $darkBlueText
    .subtitle,
    .finish-subtitle
        font-size: calc(1.5rem - 2px)
        line-height: 1.829rem
        font-weight: $light
        color: $blackText
    .finish-subtitle
        font-weight: $regular
        color: $blackText
    .small-title,
    .finish-small-title
        font-size: 1.5rem
        font-weight: $medium
        line-height: 1.875rem
        margin-bottom: 1rem
        width: 100%
    .finish-small-goodbye
        background-color: $bgGray
        padding-top: 3rem
        padding-bottom: 2rem
        width: 75%
        text-align: center
        margin-left: 15%
        margin-top: 3rem
        margin-bottom: 3rem
        border-radius: 2rem
    .medium-text
        font-size: 1.125rem
        line-height: 1.371rem
        font-weight: $regular
    .small-text
        font-size: 0.875rem
        line-height: 1.067rem
        font-weight: $medium
        padding: 0 1.5rem
        width: 100%
    .step-title
        font-size: 2rem
        line-height: 1.829rem
        font-weight: $bold
        color: $darkBlueText
        margin-bottom: 1rem
    .step-subtitle
        font-size: 1.125rem
        line-height: 1.371rem
        font-weight: $regular
        color: $blackText
    .time-detail
        font-size: 0.875rem
        line-height: 1rem
        display: inline-block
        width: 100%
        text-align: right
    .outOfZone
        color: $alertErrorText
    .finishContainer

@media screen and ( max-width: 1921px )
    body
        .main-title,
        .loading-main-title
            font-size: 2.7rem
        .small-title,
        .finish-small-title
            // font-size: calc( $fs-sm + 1rem )
        .small-text
            font-size: $fs-sm
            line-height: 1.3rem
            width: 55ch

@media screen and ( max-width: 1441px )
    body
        .main-title,
        .loading-main-title
            font-size: 2.25rem
        .small-title,
        .finish-small-title
            font-size: 1.5rem
        .small-text
            font-size: 0.875rem
            line-height: 1.067rem
            padding: 0 6.6rem
        .step-title
            font-size: 2rem
            line-height: 1.829rem
            font-weight: $bold
            color: $darkBlueText
            margin-bottom: 1rem
        .step-subtitle
            font-size: 1.125rem
            line-height: 1.371rem
            font-weight: $regular
            color: $blackText

@media screen and ( max-width: 1367px )
    body
        .main-title,
        .finish-main-title
            font-size: 1.50rem
            line-height: 2.188rem
            margin-bottom: 0
        .subtitle,
        .finish-subtitle
            font-size: 1rem
            line-height: 1.438rem
        .small-title,
        .finish-small-title
            font-size: 1.25rem
            line-height: 1.563rem
            margin-bottom: 0.5rem
        .finish-small-title
            font-size: 1.1rem
            line-height: 1.563rem
        .medium-text
            font-size: 0.875rem
            line-height: 1.063rem
        .small-text
            font-size: 0.8rem
            line-height: 1.1rem
            padding: 0 1rem
            margin-bottom: 0
        .step-title
            font-size: 1.5rem
            line-height: 1.371rem
            margin-bottom: 0.5rem
        .step-subtitle
            font-size: 0.875rem
            line-height: 1rem
        .method-detail
            font-size: 0.875rem
            line-height: 1rem
        .time-detail
            font-size: 0.75rem
            line-height: 1rem

@media screen and ( max-width: 1025px )
    body
        .main-title,
        .finish-main-title
            font-size: 1.5rem
            line-height: 1.938rem
            margin-bottom: 1rem
        .subtitle,
        .finish-subtitle
            font-size: 1rem
            line-height: 1.313rem
        .finish-small-title
            font-size: $fs-sm
            line-height: 1.5rem
        .small-text
            padding: 0 4rem

a
    &.no-decoration
        text-decoration: none