
.safe-anchor-true
    color: #0a58ca
    cursor: pointer
    background-color: transparent
    text-decoration: underline
    &:visited 
        color: #940aca
    &:hover 
        color: #2c6ecf
    &:active 
        color: #0acaa0
        text-decoration: none
.safe-anchor-false
    color: inherit
    text-decoration: none
    cursor: pointer