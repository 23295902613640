@mixin welcomeGridStyles($mainColor)
    display: flex
    justify-content: center
    align-items: start
    .logo-container
        padding-bottom: 5rem
        top: 5%
        left: 5.6rem
        position: absolute
        display: flex
        justify-content: flex-start
        align-items: center
        width: 10rem
        @media screen and (min-height: 500px) and ( min-width: 1000px) and ( max-width: 1500px)
            left: 5rem
            width: 9rem
        @media screen and (min-height: 800px )
            width: 15rem
        @media screen and ( min-width: 1500px )
            left: 6rem
    .welcome-text
        position: relative
        bottom: 0
        left: 4.5%
        @media screen and ( min-height: 800px )
            bottom: 5%
        h1
            // &::after
            //     content: ""
            //     width: 110px
            //     height: 3px
            //     position: absolute
            //     bottom: 0
            //     left: 0
            //     background-color: $mainColor

#welcome
    height: 100vh
    .row
        height: 100%
        .col
            display: flex
            flex-direction: column
            justify-content: center
        .welcome-grid
            &.secommerce
                @include welcomeGridStyles($wompi-blue)
        .form-col
            background-color: $lightText
            display: flex
            align-items: center
            .form-wrapper
                border-radius: 2rem
                border: 3px solid $wompi-light
                text-align: center
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                width: 67.5%
                height: 92%
                p
                    &.medium-text
                        margin-top: .3rem
                        width: 50%
                        text-align: center
                        margin-bottom: 2.5rem
                        @media screen and ( min-height: 600px )
                            width: 80%
                            margin-bottom: 1.5rem
                            line-height: 1.6rem
                        @media screen and ( min-height: 800px )
                            margin-bottom: 2rem
                        @media screen and (min-height: 500px) and ( min-width: 1000px) and ( max-width: 1500px)
                            width: 85%
                            margin-bottom: 1rem
                        
                form
                    width: 70%
                    margin: 1rem auto auto auto
                    text-align: left
                    margin-bottom: 1rem
                    @media screen and (min-height: 500px) and ( min-width: 1000px) and ( max-width: 1500px)
                        margin-top: 0
        .welcome-text
            padding: 3rem
            width: 55ch
            display: flex
            flex-direction: column
            justify-content: space-between
            border-radius: 15px
            p
                font-size: 1.1rem
            h1
                position: relative
                font-size: 1.7rem
                margin: 0
            .logo-bottom
                width: 100%
                display: flex
                justify-content: flex-end
                img
                    max-width: 60%
                
@media screen and (min-height: 800px )
    .welcome-text
        h1
            font-size: 2rem
        p
            font-size: 1.3rem