// Colors
$backgroundDark: #343534
$backgroundLight: #EDEDED
$backgroundPanel: #EFEFEF

// Manual de marca
$wompi-blue: #4666FF
$wompi-purple: #9063CD
$wompi-cyan: #59CBE8
$wompi-light: #DFFF61
$wompi-light-hover: #97ac42
$wompi-primary: #00825A
$wompi-primary-hover: #00573d


    

$grayMisc: #F4F4F4

$lightText: #FFFFFF
$blackText: #2E2E2E
$darkBlueText: #2A1B46

///



$backgroundLightblue: #F3F8FB
$navItems: #797979
$bgGray: #EDEDED

///


$actionBtn: rgba(70, 104, 190, 0.8)
$actionBtn-hovered: rgba(56, 84, 153, 1)
$actionBtn-activated: rgba(38, 56, 100, 0.8)

$accentBlue: #3a17ff

$alertErrorText: #F52F41
$backgroundError: #F5E6E9

$alertSuccessText: #28C76F
$alertSuccessBackground: #F2F7E6

$badge-blue: #5f79fa68
$badge-green: #00ff1e3d
$badge-yellow: #c7b4282d
$badge-red: #dc0b0b3f
$badge-purple: #c7289a37

$retryBtn: rgba(190, 70, 70, 0.5)
$retryBtn-hovered: rgba(153, 71, 56, 0.5)
$retryBtn-activated: rgba(100, 42, 38, 0.5)

$cancelBtn: rgba(245, 47, 65, 0.5)
$cancelBtn-hovered: rgba(216, 42, 57, 0.5)
$cancelBtn-activated: rgba(151, 29, 39, 0.5)

$createBtn: rgba(0, 164, 16, 0.5)
$createBtn-hovered: rgba(1, 136, 15, 0.5)
$createBtn-activated: rgba(0, 97, 10, 0.5)

// Fonts

@font-face
    font-family: 'TTNorms'
    src: local('TTNorms'), url(./fonts/TTNorms-Bold.ttf) format('truetype')
    font-weight: 700

@font-face
    font-family: 'TTNorms'
    src: local('TTNorms'), url(./fonts/TTNorms-Medium.ttf) format('truetype')
    font-weight: 500

@font-face
    font-family: 'TTNorms'
    src: local('TTNorms'), url(./fonts/TTNorms-Regular.ttf) format('truetype')
    font-weight: 400

@font-face
    font-family: 'TTNorms'
    src: local('TTNorms'), url(./fonts/TTNorms-Light.ttf) format('truetype')
    font-weight: 300

@font-face
    font-family: 'TTNorms'
    src: url("./fonts/TTNorms-Bold.ttf")
    src: url("./fonts/TTNorms-Medium.ttf")
    src: url("./fonts/TTNorms-Regular.ttf")
    src: url("./fonts/TTNorms-Light.ttf")
    
$main-font: 'TTNorms'
$light: 300
$regular: 400
$medium: 500
$semibold: 600
$bold: 700

$fs-sm: 1rem
$fs-md: 1.7rem
$fs-lg: 2.8rem



